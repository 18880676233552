.amplify-button--primary {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #0a51a5;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: 700;
  font-size: 2rem;
  padding: 2rem 0.5rem;
  min-width: 22.6rem;
  line-height: 1;
  border: 0.1rem solid #0a51a5;
  -webkit-transition: border-color 0.3s ease, color 0.3s ease;
  -o-transition: border-color 0.3s ease, color 0.3s ease;
  transition: border-color 0.3s ease, color 0.3s ease;
  padding-bottom: 10px;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: none;
  box-shadow: none;
  border: none;
}

.amplify-field-group__control {
  display: block;
  border: 0.1rem solid #c4c8cc;
  border-radius: 0.5rem;
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 0;
}

.amplify-button--small {
  border: none;
}

[data-amplify-authenticator] [data-amplify-footer] {
  display: none;
}
