.loading {
  display: flex;
  justify-content: center;
}

.loading--full-height {
  align-items: center;
  height: 100%;
}

.loading::after {
  content: "";
  width: 50px;
  height: 50px;
  border: 10px solid #dddddd;
  border-top-color: #464949;
  border-radius: 50%;
  transform: rotate(0.16turn);
  animation: loading 1s ease infinite;
}

@keyframes loading {
  /* Safari support */
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

/* Style #2 */
.loading-2 {
  display: flex;
  justify-content: center;
}

.loading-2--full-height {
  align-items: center;
  height: 100%;
}

.loading-2::after {
  content: "";
  width: 50px;
  height: 50px;
  background: #009579;
  opacity: 0.5;
  border-radius: 50%;
  animation: loading-2 0.3s ease-in-out infinite alternate;
}

@keyframes loading-2 {
  /* Safari support */
  from {
    opacity: 0;
    transform: scale(1);
  }

  to {
    opacity: 1;
    transform: scale(1.75);
  }
}
